import Vue, { VNode } from 'vue';

import gql from 'graphql-tag';
import apollo from '@/services/apollo';
import DoublePaneLayout from '@/components/Admin/DoublePaneLayout/DoublePaneLayout';

import './Client.css';

export interface Client {
  email: string;
  id: number;
  firstName: string;
  lastName: string;
  phone: string;
}

interface ClientProps {
  clients: Client[] | null;
  loading: boolean;
}

const ClientSidebarItem = (context: any) => {
  const client = context.props.item as Client;

  return (
    <li class="sidebar-item">
      <router-link to={`/admin/clients/${client.id}`}>
        {client.firstName} {client.lastName}
      </router-link>
    </li>
  );
};

const ClientPage = Vue.extend({
  data(): ClientProps {
    return {
      clients: null,
      loading: true,
    };
  },

  methods: {
    handleClientFiltering() {
      // TODO: Implement
    },

    handleClientSorting() {
      // TODO: Implement
    },

    loadClients() {
      const query = gql`
        {
          getClients{
            created
            id
            firstName
            lastName
          }
        }
      `;

      apollo.query({
        fetchPolicy: 'network-only',
        query,
      })
        .then(({ data }) => {
          this.clients = data?.getClients || [];
        })
        .catch((err) => {
          console.log('Error loading clients => ', err);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    reloadClientList() {
      this.loadClients();
    },

    searchClientsFormHandler(event: any) {
      event.preventDefault();
    },
  },

  mounted() {
    this.loadClients();
  },

  name: 'client',

  render(): VNode {
    return (
      <DoublePaneLayout
        filterHandler={this.handleClientFiltering}
        renderSidebarItem={ClientSidebarItem}
        // search={{
        //   handler: this.searchClientsFormHandler,
        //   placeholder: 'Search clients',
        // }}
        sidebarLoading={this.loading}
        sidebarItems={this.clients}
        sidebarLink={'/admin/clients/new'}
      // sortHandler={this.handleClientSorting}
      >
        <router-view {...{
          on: {
            reloadClientList: this.reloadClientList,
          },
        }}></router-view>
      </DoublePaneLayout>
    );
  },
});

export default ClientPage;
